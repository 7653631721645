import React from "react"
import SEO from "../components/seo"
import { motion } from 'framer-motion'

const duration = 0.35

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const ContactPage = () => {
  return (
    <>
      <SEO title="Contact Us" />
      <motion.section
        variants={container}
        initial="hidden" 
        animate="visible"
        className="container"
      >
        <motion.div 
          className="content"
          variants={item}
          transition="easeInOut"
        >
          <p className="text-lg md:text-xl pl-3">Contact Us</p>
        </motion.div>

        <motion.div 
          className="content"
          variants={item}
          transition="easeInOut"
        >
          <p className="pl-5"> <strong>Email:</strong> chicagoaww[at]gmail[dot]com </p>
          <p className="pl-5"> <strong>Facebook:</strong> <a className='hover:text-yellow-500' href='https://www.facebook.com/chicagoaww/'> @chicagoaww</a> </p>
          <p className="pl-5"> <strong>Twitter:</strong> <a className='hover:text-yellow-500' href='https://twitter.com/chicagoaww'>@chicagoaww</a></p>
          <p className="pl-5"> <strong>Instagram:</strong> <a className='hover:text-yellow-500' href='https://www.instagram.com/chicagoaww/'>@chicagoaww</a> </p>

        </motion.div>
      </motion.section>
    </>
  )
}

export default ContactPage